import React from "react"
import cx from 'classnames'

import { Link } from 'gatsby'

import { Image } from 'src/components/image'

import { handleSlug } from 'src/utils/handleSlug'
import { Marqy } from 'marqy'


export interface ShopAllCardsMarqueeProps {
  data: {
    title: String
    images: []
    ctaLink: {}
  }
}

export const ShopAllCardsMarquee = ({ data }: ShopAllCardsMarqueeProps) => {
  const { title, images, ctaLink } = data
  return (
    <div className={cx(`mt4 mb4 mt6--800 mb6--800`)}>
      <div className='x p4--800' />
      <div className='container--1200 mxa'>
        <h2 className='tc s80 s100--1000 p2'>{title}</h2>
      </div>
      <div className='bt bb bc mt3 mb3'>
        <Marqy speed='0.3'>
          <div className='df jcc aic pr '>
            {images.map((image, i) => (
              <div key={i} className='pods__marquee bl bc'>
                <Image className='x p4' imageId={image.image.imageId}  />
              </div>
            ))}
          </div>
        </Marqy>
        <div className='bt bc tc jcc df'>
          {ctaLink && (
            <div className='container--400 x mxa'>
              <Link className='button dib x bn button--large founders' to={handleSlug(ctaLink[0].link._type, ctaLink[0].link.slug)}>
                <span className=''>{ctaLink[0].title}</span>
              </Link>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default ShopAllCardsMarquee